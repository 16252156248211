import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Section from './Section'

import {
  Container, SubContainer, Title, SubTitle,
} from './styles'

const query = graphql`
  {
    boutique: file(relativePath: {eq: "boutique-home.jpg"}) {
      childImageSharp {
        fixed(width: 300, height: 300, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    print: file(relativePath: {eq: "print/home-serigraphy.jpg"}) {
      childImageSharp {
        fixed(width: 300, height: 300, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    gear: file(relativePath: {eq: "musicgear/music-gear-home.jpg"}) {
      childImageSharp {
        fixed(width: 300, height: 300, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const Sections = () => {
  const data = useStaticQuery(query)

  return (
    <Container>
      <SubContainer>
        <SubTitle>Tous nos produits en vente</SubTitle>
        <Title>Notre Boutique</Title>
        <Section
          kind="shop"
          reverse
          fixed={data.boutique.childImageSharp.fixed}
          btn={[{ label: 'Boutique', to: '/shop' }]}
        />
      </SubContainer>
      <SubContainer>
        <SubTitle>Produits sur mesure</SubTitle>
        <Title>Créez votre devis</Title>
        <Section
          title="Instruments"
          kind="music-gear"
          fixed={data.gear.childImageSharp.fixed}
          btn={[
            {
              label: 'Cabs',
              to: '/musicgears/cabs',
            },
            {
              label: 'Pedalboards',
              to: '/musicgears/pedalboards',
            },
            {
              label: 'Guitares',
              to: '/musicgears/guitar',
            },
            {
              label: 'Hardcases',
              to: '/musicgears/hardcase',
            },
          ]}
        />
        <Section
          title="Prints"
          kind="print"
          reverse
          fixed={data.print.childImageSharp.fixed}
          btn={[
            {
              label: 'Papier',
              to: '/prints/paper-print',
            },
            {
              label: 'Sacs',
              to: '/prints/bags',
            },
            {
              label: 'T-shirts',
              to: '/prints/t-shirts',
            },
          ]}
        />
      </SubContainer>
    </Container>
  )
}

export default Sections
